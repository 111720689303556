import {combineReducers} from 'redux';
import OrderReturn from './orderReturn';
import editOrderNew from './editPriceOrderNew';
import CountInCart from './countInCart';

const reducers = combineReducers({
    OrderReturn,
    editOrderNew,
    CountInCart,
});

export default reducers;