import { action, observable } from 'mobx';

class UrlStore {
    @observable url = '/';

    @action setUrl = (url) => {
        this.url = url == '/login' ? '/' : url;
    } 
    
    @action clearUrl = (url) => {
        this.url = '/';
    }
}

export default UrlStore;