import * as types from '../../commons/constants';

let initialState = {
    count: '',
}

let countInCart = (state = initialState, action) => {
    switch(action.type){
        case types.COUNT_IN_CART:
            return {
                ...state,
                count: action.count
            }
        default: return state
    }
}

export default countInCart;

