import'bootstrap/dist/css/bootstrap.min.css';
import'bootstrap/dist/css/bootstrap.min.css';
import $ from'jquery';
import Popper from'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from '../src/storeRedux/reducers';
import { Provider } from 'react-redux';
import { createStore } from 'redux';


ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
serviceWorker.register();
