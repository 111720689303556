import React, { Component, Suspense } from 'react';
import { configure } from 'mobx';
import routes from './routes';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { Provider as Pro } from 'mobx-react';

import reducers from '../src/storeRedux/reducers';
import appStore from './stores/applicationStore';
import packageJson from '../package.json';
import { getBuildDate } from './utils/formatDateBuild';
import withClearCache from './clearCache';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './resources/css/bootstrap.min.css';
import './styles/main.scss';
import 'antd/dist/antd.css';

configure({ enforceActions: 'always' });

const store = createStore(reducers);
const ClearCacheComponent = withClearCache(MainApp);

store.subscribe(() => {
  try {
    localStorage.setItem('store', JSON.stringify(store.getState()));
  } catch (e) {
    console.error(e);
  }
});

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <Provider store={store}>
      <Pro appStore={appStore}>
        <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>
      </Pro>
    </Provider>
  );
}

export default App;
