import * as callApi from '../utils/apiHelper';
import api from '../commons/environment';

import { ACCESS_TOKEN } from '../commons/constants';

//similar product
export const getSimilarProduct = (groupCode, data) => {
  const url = api.getSimilarProduct + groupCode;
  return callApi.get(url, data);
};

// productcare
export const getProductCare = data => {
  const url = api.product_v2;
  return callApi.get(url, data);
};

// products seen
export const getProductsSeen = data => {
  const url = api.getProductsSeen;
  return callApi.get(url, data, api.token);
};

export const getListProductsSeen = data => {
  const url = api.getListProductsSeen;
  return callApi.get(url, data);
};

export const addSeenProduct = data => {
  const url = api.addSeenProduct;
  return callApi.post(url, data);
};

//api public
export const getPopularProducts = (page, limit = 50) => {
  const url = api.popularProduct;
  return callApi.get(
    url,
    {
      filter: 'view',
      limit,
      page,
    },
    api.token,
  );
};

//api public
export const getCareProducts = (page, limit = 50) => {
  const url = api.careProduct;
  return callApi.get(
    url,
    {
      filter: 'in-cart',
      limit,
      page,
    },
    api.token,
  );
};

//api public
export const getProductById = (id, data) => {
  const url = api.detailProduct + id;
  if (data) {
    return callApi.get(url, data, api.token);
  }
  return callApi.get(url, null, api.token);
};

export const getDetailProduct_v3 = (id, data) => {
  const url =
    api.detailProduct_v3 + id.group_code + '?code_referrer=' + id.code_referrer;
  if (data) {
    return callApi.get(url, data, api.token);
  }
  return callApi.get(url, null, api.token);
};

export const getDataDetailPageVoteProduct = data => {
  const url =
    api.getDataDetailPageVoteProduct +
    data.groupCode +
    '?code_referrer=' +
    data.codeReferrer;
  return callApi.get(url, null, api.token);
};

//api public
export const getPopularProductsByCategoryId = (id, page, limit = 50) => {
  const url = api.productOnCat_Popular;
  return callApi.get(
    url,
    {
      filter: 'view',
      categoryCode: id,
      page,
      limit,
    },
    api.token,
  );
};

//api public
export const getCareProductsByCategoryId = (id, page, limit = 50) => {
  const url = api.productOnCat_Popular;
  return callApi.get(
    url,
    {
      filter: 'in-cart',
      categoryCode: id,
      page,
      limit,
    },
    api.token,
  );
};

export const getProducts = ({
  filter = 'view',
  limit = 50,
  page = 1,
  sort = 'stock',
}) => {
  const url = `${api.getProducts}?filter=${filter}&limit=${limit}&page=${page}&sort=${sort}`;
  return callApi.get(
    url,
    {
      filter,
      limit,
      page,
    },
    api.token,
  );
};

//api public
export const getProductsByCategoryId = ({
  id,
  filter = 'view',
  limit = 50,
  page = 1,
  sort = 'stock',
}) => {
  const url = api.getProducts;
  return callApi.get(
    url,
    {
      filter,
      limit,
      categoryCode: id,
      page,
      sort,
    },
    api.token,
  );
};

//get products vote
export const getProductsVote = ({ limit = 48, page = 1 }) => {
  const url = api.product_vote;
  return callApi.get(
    url,
    {
      limit,
      page,
    },
    api.token,
  );
};

//handle voting product
export const voting = data => {
  const url = api.product_voting;
  return callApi.post(url, data);
};

export const getDetailVoting = ({ id, campaignCode }) => {
  const url = api.detail_product_vote + id;
  return callApi.get(url, { campaignCode }, api.token);
};

export const getListVote = ({ limit = 48, page = 1 }, token = null) => {
  const url = api.product_vote_of_colla;
  return callApi.get(
    url,
    {
      limit,
      page,
    },
    token,
  );
};

// export const getProductWebsite = () => {
//   const url = api.getProductsWebsite;
//   return callApi.get(url, )
// }

//web ctv
export const getProductWebsite = ({
  filter = 'view',
  limit = 50,
  page = 1,
  domain = '',
}) => {
  const url = api.getProductsWebsite;
  return callApi.get(
    url,
    {
      filter,
      page,
      limit,
      domain,
    },
    api.token,
  );
};

//web ctv
export const getProductsWebsiteByCategoryId = ({
  categoryCode = '',
  filter = 'view',
  limit = 50,
  page = 1,
  domain = '',
}) => {
  const url = api.getProductsWebsite;
  return callApi.get(
    url,
    {
      filter,
      page,
      limit,
      categoryCode,
      domain,
    },
    api.token,
  );
};

//web ctv
export const listProductsForCollaboratorAddToWeb = ({
  categoryCode = '',
  collaboratorCategoryCode = '',
  filter = 'time',
  sort = 'desc',
  limit = 50,
  page = 1,
  type_product = 'default',
  size_pattern = true,
}) => {
  const url = api.listProductsForCollaboratorAddToWeb;
  if (!collaboratorCategoryCode) {
    return callApi.get(url, {
      categoryCode,
      filter,
      sort,
      limit,
      page,
      type_product,
      size_pattern,
    });
  }
  return callApi.get(url, {
    categoryCode,
    collaboratorCategoryCode,
    filter,
    sort,
    limit,
    page,
    type_product,
    size_pattern,
  });
};

//web ctv
export const addCategoryProducts = data => {
  const url = api.addCategoryProducts;
  return callApi.post(url, data);
};

//web ctv
export const getDetailProductWebsite = ({ id, domain = '' }) => {
  const url = api.getDetailProductWebsite + id;
  return callApi.get(url, { domain });
};

// export const getProductForVote = () => {
//   const url = api.getProductForVote;
//   return callApi.get(url, null, api.token);
// };

export const getProductForVote = ({
  limit = 50,
  page = 1,
  categoryCode = '',
  code_referrer = '',
  type_category = '',
}) => {
  const data = {
    limit,
    page,
    categoryCode,
    code_referrer,
  };
  const url = api.getProductForVote;
  return callApi.get(url, data, api.token);
};

export const getSizeProductForVote = groupCode => {
  const url = api.getSizeProductForVote + groupCode;
  return callApi.get(url, null, api.token);
};

export const productRequestStock = ({ groupCode, data }) => {
  const url = api.productRequestStock + groupCode;
  return callApi.post(url, data);
};

export const productRequestStockFilter = () => {
  const url = api.productRequestStockFilter;
  return callApi.get(url);
};

export const productVotetStockFilter = () => {
  const url = api.productVotetStockFilter;
  return callApi.get(url);
};

export const getListProductVoteStock = data => {
  const url = api.getListProductVoteStock;
  return callApi.get(url, data);
};

export const getListProductRequireStock = data => {
  const url = api.getListProductRequireStock;
  return callApi.get(url, data);
};

export const productVoteStock = ({ groupCode, data }) => {
  const url = api.productVoteStock + groupCode;
  return callApi.post(url, data);
};

export const getDetailProductRequestStock = productCode => {
  const url = api.getDetailProductRequestStock + productCode;
  return callApi.get(url);
};

export const getDetailProductVoteStock = productCode => {
  const url = api.getDetailProductVoteStock + productCode;
  return callApi.get(url);
};

export const updateDetailProductRequestStock = ({ productCode, data }) => {
  const url = api.updateDetailProductRequestStock + productCode;
  return callApi.post(url, data);
};

export const updateDetailProductVoteStock = ({ productCode, data }) => {
  const url = api.updateDetailProductVoteStock + productCode;
  return callApi.post(url, data);
};

//webctv
export const updateProductWebsite = ({
  group_code,
  collaborator_price,
  description,
  web_product_name,
  original_price,
  promotion_start,
  promotion_end,
  promotion_show,
}) => {
  const url = api.updateProductWebsite;
  return callApi.post(url, {
    group_code,
    collaborator_price,
    description,
    original_price,
    web_product_name,
    promotion_start,
    promotion_end,
    promotion_show,
  });
};

//webctv
export const removeProductWebsite = ({ group_code, category_code }) => {
  const url = api.removeProductWebsite;
  return callApi.post(url, {
    group_code,
    category_code,
  });
};

export const getProductV2 = ({
  filter = 'time',
  limit = 50,
  page = 1,
  sort = 'desc',
  categoryCode = '',
  domain = '',
  code_referrer = '',
  size = '',
  type_product = '',
  type_category = '',
  ecommerce = 0,
}) => {
  // let url = `${api.domain_product}?filter=${filter}&limit=${limit}&page=${page}&sort=${sort}&code_referrer=${code_referrer}&size=${size}`;
  // if(categoryCode !== ''){
  //   url = `${api.domain_product}?filter=${filter}&limit=${limit}&page=${page}&sort=${sort}&categoryCode=${categoryCode}&code_referrer=${code_referrer}&size=${size}`;
  // }
  const url = api.domain_product;
  const data = {
    filter,
    limit,
    page,
    sort,
    code_referrer,
    ecommerce,
  };

  if (size) {
    data.size = size;
  }

  if (categoryCode !== '') {
    data.categoryCode = categoryCode;
  }

  if (type_category) {
    data.type_category = type_category;
  }

  if (domain) {
    data.domain = domain;
    return callApi.get(url, data, api.token);
  }

  // if(domain){
  //   return callApi.get(
  //     url,
  //     {
  //       filter,
  //       limit,
  //       page,
  //       sort,
  //       domain,
  //     },
  //     api.token,
  //   );
  // }

  // lấy sp vip, private
  if (type_product !== '') {
    data.type_product = type_product;
    const tokenUser = localStorage.getItem(ACCESS_TOKEN);
    return callApi.get(url, data, tokenUser);
  }

  return callApi.get(url, data, api.token);
};

export const editPriceUsingExportProduct = data => {
  const url = api.editPriceUsingExportProduct;
  return callApi.get(url, data);
};

export const editPriceUsingImportProduct = data => {
  const url = api.editPriceUsingImportProduct;
  return callApi.uploadFile(url, data);
};
