import { getListVote } from '../services/products';
import { PRODUCT_VOTE, ACCESS_TOKEN } from '../commons/constants';
export const getProductVote = async (token) => {
    let arrCode = [];
    
    if(!token){
        token = await localStorage.getItem(ACCESS_TOKEN);
    }
    if(!token) return;
    
    try {
        const response = await getListVote({}, token);
        if(response.status == 200){
            for(let item of response.data.data){
                arrCode.push(item[0].code);
            }
            await localStorage.removeItem(PRODUCT_VOTE);
            await localStorage.setItem(PRODUCT_VOTE, JSON.stringify(arrCode));
        }
    } catch (error) {
        
    }
}