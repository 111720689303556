import isEqual from 'lodash/isEqual';
export function removeItem(array, value, deepKey) {
  if (deepKey) {
    for (var i = 0; i < array.length; i++) {
      if (isEqual(array[i][deepKey], value[deepKey])) {
        array.splice(i, 1);
      }
    }
    return array;
  }

  for (var i = 0; i < array.length; i++) {
    if (isEqual(array[i], value)) {
      array.splice(i, 1);
    }
  }
  return array;
}

export function addItem(array, value, deepKey) {
  if (deepKey) {
    for (var i = 0; i < array.length; i++) {
      if (isEqual(array[i][deepKey], value[deepKey])) {
        return array;
      }
    }
    array.push(value);
    return array;
  }

  for (var i = 0; i < array.length; i++) {
    if (isEqual(array[i], value)) {
      return array;
    }
  }
  array.push(value);
  return array;
}

export function addItemWithCount(array = { count: 1 }, value) {
  for (var i = 0; i < array.length; i++) {
    if (
      isEqual(array[i].productCode, value.productCode) &&
      isEqual(array[i].size, value.size)
    ) {
      array[i].count += 1;
      return array;
    }
  }
  array.push(value);
  return array;
}

export function compareArr(arrA, arrB) {
  if (arrA.length !== arrB.length) {
    return false;
  } else {
    for (let i = 0; i < arrA.length; i++) {
      if (arrA[i] !== arrB[i]) {
        return false;
      }
    }
    return true;
  }
}

export function moveItemArr(arr, fromIndex, toIndex) {
  let arrCopy = arr.map(item => ({ ...item }));
  let element = arrCopy[fromIndex];
  arrCopy.splice(fromIndex, 1);
  arrCopy.splice(toIndex, 0, element);
  return arrCopy;
}
